import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      name: 'FrontPage',
      path: '/',
      component: () => import("../components/tomansion/FrontPage"),
    },

  ],
})

    // // === Selection
    // {
    //   name: 'selection',
    //   path: '/selection',
    //   component: () => import("../components/debiai/selection/Selection"),
    //   children: [
    //     {
    //       name: 'projects',
    //       path: '/',
    //       component: () => import('../components/debiai/selection/projects/Projects'),
    //     },
    //     {
    //       name: 'project',
    //       path: '/project',
    //       component: () => import('../components/debiai/selection/project/Project'),
    //     },

    //     // Blocks
    //     {
    //       name: 'blocks',
    //       path: '/selections/project/blocks',
    //       component: () => import('../components/debiai/selection/project/blocks/Blocks'),
    //     },
    //     {
    //       name: 'blockR',
    //       path: '/selections/project/blocks/blockR',
    //       component: () => import('../components/debiai/selection/project/blocks/BlockRedirect'),
    //     },
    //     {
    //       name: 'block',
    //       path: '/selections/project/blocks/block',
    //       component: () => import('../components/debiai/selection/project/blocks/BlockPage'),
    //     },

    //     // Requests
    //     {
    //       name: 'requestCreation',
    //       path: '/selections/project/requestCreation',
    //       component: () => import('../components/debiai/selection/project/requests/RequestCreation'),
    //     },
    //     // Selections
    //     {
    //       name: 'selections',
    //       path: '/selections/project/selections',
    //       component: () => import('../components/debiai/selection/project/selections/Selections'),
    //     },
    //   ]
    // },

    // // === Analytics
    // {
    //   name: 'analytics',
    //   path: '/analytics',
    //   component: () => import("../components/debiai/analyse/Analyse"),
    // },

    // // === Statistics
    // {
    //   name: 'statistics',
    //   path: '/statistics',
    //   component: () => import("../components/debiai/statistics/Statistics"),
    //   children: [
    //     {
    //       name: 'dataSelection',
    //       path: '/',
    //       component: () => import("../components/debiai/statistics/dataSelection/DataSelection"),
    //     },
    //     {
    //       name: 'modelSelection',
    //       path: '/modelSelection',
    //       component: () => import("../components/debiai/statistics/dataSelection/modelSelection/ModelSelection"),
    //     },
    //     {
    //       name: 'dataAnalysis',
    //       path: '/dataAnalysis',
    //       component: () => import("../components/debiai/statistics/dataAnalysis/DataAnalysis"),
    //     }
    //   ]
    // },
