<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html {
  height: 100%;
}
body {
  color: #2c3e50;
  background: #ecf0f1;
  padding: 0;
  margin: 0;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}
#app {
  height: 100%;
}
</style>
